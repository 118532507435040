/**
 * A helper composable function meant to simplify the process of mounting lazy modal components.
 * We often need to have a flag to indicate that the component has been mounted at least once,
 * so that we can use it in a `v-if` directive to avoid re-mounting the component every time it's shown.
 *
 * This composable function returns two reactive flags:
 * - `is` - a flag used for opening / closing the modal
 * - `was` - a flag that indicates whether the component has been mounted at least once
 *
 * @example
 * <template>
 *     <Modal v-if="was" :open="is" />
 *     <button @click="is = !is">Toggle Modal</button>
 * </template>
 *
 * <script lang="ts" setup>
 *     const { is, was } = useLazyMount()
 * </script>
 *
 */
export default function useLazyMount(init?: boolean) {
    const is = shallowRef<boolean>(init ?? false)
    const was = shallowRef<boolean>(init ?? false)

    if (import.meta.client && !is.value) {
        const unwatch = watch(is, (val) => {
            if (!val) return
            was.value = true
            unwatch()
        }, {
            immediate: true,
        })
    }

    return {
        is: is,
        was: readonly(was),
    }
}
