import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { CartModel } from '../models/cart.model'

class CartItemsTransferApiService<M extends ApiModel> extends ApiService<M> {
    post(data: { source_cart_id: string }, options?: ApiServiceFetchOptions<M>) {
        return this
            .fetch({
                ...options,
                method: 'POST',
                body: data,
            })
    }
}

export function getCartItemsTransferApiService(options: {
    targetCartId: string
}) {
    return new CartItemsTransferApiService({
        endpoint: `/carts/${options.targetCartId}/transfer-items`,
    }, CartModel)
}
