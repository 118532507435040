import type { ApiNullableDateTimeRange } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [BannerModel.ATTR_ID]: number
    /**
     * ID of the dynamically added banner types in the administration
     */
    [BannerModel.ATTR_BANNER_TYPE_ID]: number
    [BannerModel.ATTR_SIZE_TYPE]: number    // TODO: enum
    [BannerModel.ATTR_MOBILE_IMAGE_ID]: number | null
    [BannerModel.ATTR_IMAGE_ID]: number | null
    [BannerModel.ATTR_POSITION]: number
    [BannerModel.ATTR_IS_ACTIVE]: boolean
    [BannerModel.ATTR_NAME]: string
    [BannerModel.ATTR_CONTENT]: string
    [BannerModel.ATTR_URL]: string
    [BannerModel.ATTR_URL_TEXT]: string | null
    [BannerModel.ATTR_BUTTON_TEXT]: string | null
    [BannerModel.ATTR_BUTTON_LINK]: string | null
    [BannerModel.ATTR_BUTTON_IMAGE_ID]: number | null
    [BannerModel.ATTR_VALIDITY]: ApiNullableDateTimeRange
}

interface Embeds {
    [BannerModel.EMBED_MOBILE_IMAGE_URL]?: string | null
    [BannerModel.EMBED_IMAGE_URL]?: string | null
    [BannerModel.EMBED_BUTTON_IMAGE_URL]?: string | null
    [BannerModel.EMBED_BANNER_TYPE]?: BannerType
}

export class BannerModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_BANNER_TYPE_ID = 'banner_type_id'
    static readonly ATTR_SIZE_TYPE = 'size_type'
    static readonly ATTR_MOBILE_IMAGE_ID = 'mobile_image_id'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_CONTENT = 'content'
    static readonly ATTR_URL = 'url'
    static readonly ATTR_URL_TEXT = 'url_text'
    static readonly ATTR_BUTTON_TEXT = 'button_text'
    static readonly ATTR_BUTTON_LINK = 'button_link'
    static readonly ATTR_BUTTON_IMAGE_ID = 'button_image_id'
    static readonly ATTR_VALIDITY = 'validity'

    static readonly EMBED_MOBILE_IMAGE_URL = 'mobile_image_url'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_BUTTON_IMAGE_URL = 'button_image_url'
    static readonly EMBED_BANNER_TYPE = 'banner_type'

    get id() {
        return this._getAttribute(BannerModel.ATTR_ID)
    }

    get bannerTypeId() {
        return this._getAttribute(BannerModel.ATTR_BANNER_TYPE_ID)
    }

    get sizeType() {
        return this._getAttribute(BannerModel.ATTR_SIZE_TYPE)
    }

    get mobileImageId() {
        return this._getAttribute(BannerModel.ATTR_MOBILE_IMAGE_ID)
    }

    get imageId() {
        return this._getAttribute(BannerModel.ATTR_IMAGE_ID)
    }

    get position() {
        return this._getAttribute(BannerModel.ATTR_POSITION)
    }

    get isActive() {
        return this._getAttribute(BannerModel.ATTR_IS_ACTIVE)
    }

    get name() {
        return this._getAttribute(BannerModel.ATTR_NAME)
    }

    get content() {
        return this._getAttribute(BannerModel.ATTR_CONTENT)
    }

    get url() {
        return this._getAttribute(BannerModel.ATTR_URL)
    }

    get urlText() {
        return this._getAttribute(BannerModel.ATTR_URL_TEXT)
    }

    get buttonText() {
        return this._getAttribute(BannerModel.ATTR_BUTTON_TEXT)
    }

    get buttonLink() {
        return this._getAttribute(BannerModel.ATTR_BUTTON_LINK)
    }

    get buttonImageId() {
        return this._getAttribute(BannerModel.ATTR_BUTTON_IMAGE_ID)
    }

    get validity() {
        return this._getAttribute(BannerModel.ATTR_VALIDITY)
    }

    get mobileImageUrl() {
        return this._getEmbed(BannerModel.EMBED_MOBILE_IMAGE_URL)
    }

    get imageUrl() {
        return this._getEmbed(BannerModel.EMBED_IMAGE_URL)
    }

    get buttonImageUrl() {
        return this._getEmbed(BannerModel.EMBED_BUTTON_IMAGE_URL)
    }

    get bannerType() {
        return this._getEmbed(BannerModel.EMBED_BANNER_TYPE)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface BannerType {
    id: number
    name: string
}
